<script setup>
import VChart from "vue-echarts"
import { use } from "echarts/core"
import { CanvasRenderer } from "echarts/renderers"
import { BarChart, LineChart } from "echarts/charts"
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components"
import formatResult from "~/utils/results/formatResult"
import { computed, ref } from "vue"

use([
  BarChart,
  CanvasRenderer,
  GridComponent,
  LegendComponent,
  LineChart,
  TitleComponent,
  TooltipComponent,
])

const props = defineProps({
  resultCap: {
    type: Number,
    default: null,
  },
  resultsArrayDiscrete: {
    type: Array,
    required: true,
  },
  resultsArrayCumulative: {
    type: Array,
    required: true,
  },
  tabId: {
    type: String,
    default: null,
  },
})

const resultsArrayBars = computed(() => {
  return props.resultsArrayDiscrete.map((result) => {
    return {
      value: [result[0], result[1]],
      itemStyle: {
        color: "#2196F3",
        opacity: 0.8,
      },
    }
  })
})

const chartData = ref({
  alignTicks: true,
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    backgroundColor: "rgba(18, 18, 18, 1)",
    textStyle: {
      color: "#fff",
      fontFamily: '"Roboto", sans-serif',
    },
    borderWidth: 0,
    className: "echarts-custom-tooltip",
    extraCssText: "z-index: 100;",
    formatter(params) {
      const percentageString = (inputValue) => {
        if (inputValue < 0.5) {
          return "<0.5%"
        }
        return `${formatResult(inputValue)}%`
      }
      return `
            <div class="series">
                <div class="series-icon" style="background-color: ${
                  params[0].color
                }"></div>
                <div class="series-text">
                    ${params[0].name}: ${percentageString(params[0].value[1])}
                </div>
            </div>
            <div class="series">
                <div class="series-icon" style="background-color: ${
                  params[1].color
                }"></div>
                <div class="series-text">
                    ${params[1].name}+: ${percentageString(params[1].value[1])}
                </div>
            </div>
           `
    },
  },
  grid: {
    left: 0,
    right: 1,
    bottom: 20,
    containLabel: true,
  },
  legend: {
    data: [
      {
        name: "Discrete",
        icon: "rect",
      },
      {
        name: "Cumulative",
      },
    ],
    left: 0,
    selectedMode: false,
    textStyle: {
      color: "rgba(255, 255, 255, 0.6)",
    },
  },
  xAxis: {
    name: "Result",
    nameLocation: "middle",
    nameGap: 30,
    type: "category",
    axisLine: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.6)",
      },
    },
    axisTick: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.4)",
      },
    },
  },
  yAxis: [
    {
      name: "D%",
      nameLocation: "start",
      nameGap: 30,
      nameTextStyle: {
        padding: [0, 25, 0, 0],
      },
      type: "value",
      position: "left",
      axisLine: {
        lineStyle: {
          color: "rgba(255, 255, 255, 0.6)",
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(255, 255, 255, 0.4)",
          type: "dashed",
        },
      },
      min: 0,
    },
    {
      name: "C%",
      axisLabel: {
        formatter(value) {
          return Math.round(value)
        },
      },
      nameLocation: "start",
      nameGap: 30,
      nameTextStyle: {
        padding: [0, 0, 0, 35],
      },
      type: "value",
      position: "right",
      axisLine: {
        lineStyle: {
          color: "rgba(255, 255, 255, 0.6)",
        },
      },
      splitLine: {
        show: false,
      },
      max: 100,
      min: 0,
    },
  ],
  series: [
    {
      name: "Discrete",
      data: resultsArrayBars,
      type: "bar",
      yAxisIndex: 0,
      itemStyle: {
        color: "#2196F3",
      },
    },
    {
      name: "Cumulative",
      data: props.resultsArrayCumulative,
      type: "line",
      yAxisIndex: 1,
      color: "#AB47BC",
      smooth: true,
      areaStyle: {
        opacity: 0,
      },
    },
  ],
})
</script>

<template>
  <VChart class="chart" autoresize :option="chartData" />
</template>

<style lang="scss">
@use "sass:map";
@use "vuetify/settings" as vuetify;

.chart {
  height: 350px;
  @media (orientation: landscape) {
    max-height: 70vh;
  }
  @media (orientation: portrait) {
    max-height: 40vh;
    @media #{map.get(vuetify.$display-breakpoints, 'md-and-up')} {
      max-height: 45vh;
    }
    @media #{map.get(vuetify.$display-breakpoints, 'lg-and-up')} {
      max-height: 50vh;
    }
  }
}

.echarts-custom-tooltip {
  .series {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .series-icon {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 0.5rem;
  }

  .series-text {
    flex-grow: 1;
    line-height: 1;
  }
}
</style>
